.cus-padding-left-10 {
    padding-left: 10px !important;
}

.cus-nav-link {
    color: #ffff !important;
    font-style: 'Poppins', sans-serif !important;
    padding-left: 10px !important;
    padding-right: 5px !important;
}

.cus-menu-a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    position: relative;
    display: inline-block;
    padding-top: 9px;
}


.cus-menu-a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background: #fff;
    left: 0;
    bottom: 0;
    transition: width 0.3s ease-in-out;
    transform: translateX(7%);
}

.cus-menu-a:hover::after,
.cus-menu-a.cus-active::after {
    width: 85%;
    margin-left: 7px;
}

.cus-bg {
    background-color: #8b3eea;
}

.parent-menu-title div {
    background-color: #8b3eea !important;
}

.parent-menu-title a {
    color: white !important;
}

.parent-menu-title a :hover {
    color: black !important;
}

.dropdown-item:hover {
    background-color: #8b3eea;
}

.cm {
    margin-right: 40px;
}

.position-rel {
    position: relative !important;
}

/* .cusdd {
    border: 1px solid #fff;
    border-radius: 10px;
} */

.default-menu-font {
    font-size: 18px;
}

.support {
    padding-top: 10px;
}

.collaps-brand-name-container {
    padding-left: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    display: none;
}

.collaps-brand-name {
    font-size: 25px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
}
.marginLeft2Px{
    margin-left: 2px;
}
/* Media Query */
@media (max-width: 992px) {
    .custom-dropdown-menu {
        padding-left: 20px;
    }
}

@media (max-width: 991px) {
    .collaps-brand-name-container {
        display: block;
    }

    .navbar-collapse {
        position: fixed;
        top: 0;
        left: -300px;
        /* Hidden off-screen initially */
        width: 300px;
        /* Set a width for the sliding menu */
        height: 100%;
        /* Full height */
        background-color: #8b3eea;
        /* Match your navbar background color */
        transition: all 0.2s ease-in-out;
        z-index: 9999;
        /* Ensure it appears above other content */
    }

    .navbar-collapse.show {
        left: 0;
        /* Slide in from the left */
    }

    .navbar-toggler {
        position: relative;
        z-index: 10000;
        /* Ensure the button appears above the menu */
    }

    .cus-menu-a:hover::after,
    .cus-menu-a.cus-active::after {
        width: 30%;
        margin-left: 0px;
    }

    .custom-dropdown-menu {
        padding-left: 10px;
    }

    .nav-collapse-def-padding {
        padding-left: 10px;
    }

}

@media (max-width:1206px) {
    .cus-menu-a {
        font-size: 15px;
        padding-top: 10px;
    }

    .cus-nav-link {
        padding-left: 10px !important;
    }

    .default-menu-font {
        padding-top: 1px;
        font-size: 15px;
    }

    .cus-menu-a:hover::after,
    .cus-menu-a.cus-active::after {
        margin-left: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1049px) {
    .cus-menu-a {
        font-size: 13px;
        padding-top: 9px;
    }

    .default-menu-font {
        padding-top: 0px;
        font-size: 13px;
    }
}