/* Ensure the parent container is flexible and responsive */
.destinations_content_box_cus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow from images */
    height: 100%; /* Ensure it takes up all available height */
}

.destinations_content_box_cus img {
    width: 100%; /* Ensure the image takes up the full width of the container */
    height: 200px; /* Set a fixed height for all images */
    object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
    border-radius: 10px;
}

/* Add responsiveness for different devices */
@media (max-width: 1200px) {
    .destinations_content_box_cus img {
        height: 180px;
    }
}

@media (max-width: 992px) {
    .destinations_content_box_cus img {
        height: 160px;
    }
}

@media (max-width: 768px) {
    .destinations_content_box_cus img {
        height: 140px;
    }
}

@media (max-width: 576px) {
    .destinations_content_box_cus img {
        height: 120px;
    }
}
