
.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: #fff;
    background-color: var(--main-color);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .9s;
    transition: .9s;
    border-radius: 50%
}

.go-top.active {
    top: 98%;
    -webkit-transform: translateY(-98%);
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible
}

.go-top i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: .6s;
    transition: .6s
}

.go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%
}

.go-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #393953;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .6s;
    transition: .6s;
    border-radius: 50%
}

.go-top:hover,
.go-top:focus {
    color: #fff
}

.go-top:hover::before,
.go-top:focus::before {
    opacity: 1;
    visibility: visible
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%
}