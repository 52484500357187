.top_details_four_item-c {
    position: relative;
    overflow: hidden;
    width: 100%; /* Ensure the container takes up the full width */
    height: 400px; /* Set a fixed height for the container */
}

.top_details_four_item-c img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire container */
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.top_details_posation-c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 2;
}

.top_details_posation-c p,
.top_details_posation-c h3 {
    margin: 0;
}

.top_details_four_item-c::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: darken the image */
    z-index: 1;
}
