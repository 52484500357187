   .custombody {
       font-family: 'Inter', sans-serif;
       height: 100vh;
   }

   .search {
       animation: search 4s infinite;
   }

   @keyframes search {
       25% {
           top: 30px;
       }

       50% {
           left: 110px;
           transform: rotate(-80deg);
       }
   }

   .custombody .container {
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       position: relative;
       height: 99%;
   }

   .custombody .container-content {
       display: flex;
       align-items: center;
       justify-content: center;
       position: relative;
   }

   .custombody .container-content p {
       font-size: 80px;
       font-weight: 800;
       margin: 0 10px;
   }

   .custombody .container-content span {
       font-size: 70px;
   }

   .custombody .container-content .search {
       position: absolute;
       left: 60px;
       top: 26px;
       font-size: 50px;
   }

   .backBtnContainer {
       padding: 12px;
       background-color: #673AB7;
       border-radius: 10px;

   }

   .backBtnContainer .backBtn {
       color: #fff;
   }