.img-slide-responsive img {
  height: 150px;
  width: 300px;
  padding-right: 10px;
}

.big-slider img {
  height: 380px;
  width: 100%;
}

.c-pointer {
  cursor: pointer;
}

.strt-frm {
  color: #8b3eea;
}

.c-text-wrap {
  text-wrap: nowrap;
}

.cm-0 {
  margin: 0px;
}

.t-left {
  text-align: left;
}

.c-width-100 {
  width: 100%;
}

.finder-cate-dropdown {
  height: 96px;
}

.finder-cate-btn {
  background-color: #f5eefd !important;
  border: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #757575 !important;
}

.c-mx-auto {
  margin: auto;
}

.text-title {
  color: black;
  font-size: 15px;
  font-weight: 600;

}

.cus-flight_logo img {
  width: 150px;
  height: auto;
  max-height: 90px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.font-1rem {
  font-size: 1.6rem;
}

.font-sec {
  color: black;
  font-weight: 500;
}

.faresummary-content {
  color: black;
  font-weight: 400;
}

.sec {
  font-size: 18px;
  font-weight: 600;
}

.sec-timing {
  border: none !important;
  padding-bottom: 0px !important;
}

.flight-t {
  font-size: 15px;
  font-weight: 500;
}

.border-none {
  border: none;
}

.custom-input-font-style {
  font-weight: 500;
  color: #5f5f5f;
}

.custom-date-font-style {
  font-weight: 600 !important;
  color: black !important;
}

.paid-amount {
  border-top: 1px solid #6c757d;
  padding-top: 20px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fd-right {
  width: 135px !important;
  justify-content: end !important;
}

.fd-left {
  width: 135px !important;
}

.custom-badge {
  width: 115px !important;
  background-color: #f3d1a3;
  padding: 3px;
  border-radius: 8px;
  font-weight: 600;
  color: black !important;
}

.payment-type-container {
  border: 1px solid #8b3eea;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
}

.selected-payment-type {
  background-color: #8b3eea;
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.ticket-price-lable {
  font-size: 25px;
  font-weight: 600;
  margin-left: -2px;
  margin-bottom: 2px;
  line-height: 25px;
}

.cus-text-white {
  color: white !important;
}

.counter-icon {
  height: 40px;
}

.about-icon {
  height: 100px;
}

.cus-text-black {
  color: black;
}

.pl-0 {
  padding-left: 0 !important;
}

.flight_list_padding {
  padding: 40px 0;
}

.f-30 {
  font-size: 20px;
}

.flight-fliter-bottom-title {
  font-weight: 600;
  font-size: 15px;
}

.flight-filter-bottom {
  display: none !important;

}

.filter-icon {
  display: block !important;
}

.arrow-icon {
  font-size: 25px;
  color: #8b3eea;
}

.login-confirmation-header {
  border-bottom: none !important;
}

.login-confirmation-footer {
  display: flex !important;
  justify-content: center !important;
  border-top: none !important;
}

.login-confirmation-footer .btn-login {
  border: none !important;
  background-color: #8b3eea !important;
  transition: transform 0.3s ease;
}

.login-confirmation-footer .btn-login:hover {
  transform: scale(1.05);
  /* Slightly increase size */
}

.baggage-container {
  padding-top: 40px;
  padding-bottom: 10px;
  width: 100%;
}

.baggage-title {
  color: #4c4c4c;
}

.cabin-bag-icon {
  margin-right: 10px;
  font-size: 20px;
}

.baggage-type {
  font-weight: 500;
  margin-right: 10px;
}

.baggage-quantity {
  font-weight: 600;
}

.review-flight-details {
  margin-top: 2px !important;
}

/* .flight-booking{
  background-color: #efeff0;
} */
.flight-booking-container-padd {
  padding-top: 30px !important;
}

.flight-booking-header {
  margin: 0 !important;
  height: 50px;
  background-color: #fff;
  justify-content: space-between;
  border-bottom: 1px solid #cec0c0;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
}

.e-ticket-header {
  margin: 0 !important;
  height: 50px;
  background-color: #fff;
  justify-content: space-between;
  border-bottom: 1px solid #cec0c0;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
}

.flight-booking-header-title {
  font-size: 18px;
  font-weight: 600;
}

.flight-booking-header-back-icon {
  font-size: 18px;
  cursor: pointer;
}

.flight-booking-header-share-btn {
  font-size: 18px;
  cursor: pointer;
}

.font-black {
  color: black !important;
}

.margintop10 {
  margin-top: 10px;
}

.rf-left-sidebar {
  margin-top: 30px !important;
}

.fb-left-sidebar {
  margin-top: 30px !important;
}

.p-arrowRight {
  padding-left: 5px;
}

.p-arrowRight {
  padding-left: 5px;
}

.stoppageDet {
  margin-top: 5px !important;
}

.flight_det_wrapper_right {
  justify-content: center;
}

.payBtn {
  font-weight: 500;
}

.marginBottom3 {
  margin-bottom: 3px;
}

/* Customer service */

.customer_service_boxed {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.customer_service_boxed .content-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  /* Space between image and text */
}

.customer_service_boxed .icon-image {
  width: 60px;
  /* Adjust icon size */
  height: 60px;
  flex-shrink: 0;
}

.customer_service_boxed .text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.customer_service_boxed h5 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.customer_service_boxed .icon-group {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.customer_service_boxed .icon-group a {
  color: black !important;
}

.customer_service_boxed .icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s;
}

.customer_service_boxed .icon:hover {
  transform: scale(1.2);
}

/* end here  */
.mgBtm20 {
  margin-bottom: 20px !important;
}

.mgTop5px {
  margin-top: 10px !important;
}

.payment-success-container .success {
  box-shadow: 0 15px 25px #00000019;
  width: 400px;
  text-align: center;
  padding-bottom: 15px;
}

.payment-success-container .success i {
  font-size: 55px;
  color: #28a745;
}

.payment-success-container .success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

.payment-success-container {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.ticket-booking-details {
  margin-top: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ticket-booking-details .sector {
  font-weight: 700;
}

.ticket-booking-details .time-diff {
  font-weight: 500;
}

.ticket-booking-details .travel-date {
  font-weight: 400;
}

.ftWeight600 {
  font-weight: 600 !important;
}

.mgTop4px {
  margin-top: 4px !important;
}

/* Boooked Ticket */
.booked-ticket-container {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.padTop60 {
  padding-top: 60px !important;
}

.cusSesctionPaddingTop {
  padding-top: 60px !important;
}

.ticket-details .sector {
  font-size: 20px;
  font-weight: 500;
}

.ticket-details .stoppage {
  font-size: 15px;
}

.ticket-details .time-diff {
  font-size: 13px;
}

/*FLight Ticket List  */
.ticket-action {
  display: flex;
  gap: 10px;
  padding-top: 10px;
}

.customBtn {
  background-color: #8b3eea !important;
  color: white !important;
}

.customBtn:hover {
  transform: scale(1.1);
  /* Adjust scale as needed */
  transition: transform 0.3s ease;
  /* Smooth transition */
}

.pd0 {
  padding: 0px !important;
}

.btn-price {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 6px;
  font-weight: 500;
  user-select: none;
}

.rupeeIcon {
  margin-bottom: 2px;
}

.ticketPrice {
  margin-left: -5px;
}

/* End Here */
.isSoldBtn {
  background: #9E9E9E !important;
  color: #fff;
  padding: 10px 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
}

/* Search Flight Filter */
.departureTimeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.departureTimeContainer .item {
  flex-basis: 45%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.departureTimeContainer .item .dtitle {
  color: black;
  font-size: 14px;
  font-weight: 600 !important;
  margin-bottom: -3px;

}

.departureTimeContainer .item .dsubtitle {
  font-size: 12px;
  font-weight: 500;
  color: #5e616e;
}

.departureTimeContainer .selecteditem {
  background-color: #673AB7;
  color: white !important;
  border: none !important;
}

.departureTimeContainer .selecteditem p {
  color: white !important;
}

/* Accounts */
.cardContainer {
  display: flex;
  gap: 10px;
}

.cardContainer .cardTitle {
  margin-bottom: -5px;
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.cardContainer .cardSubTitle {
  color: black;
  font-weight: 500;
}

.customTopHeader {
  padding: 12px;
  background-color: #f0f0f0;
}

.customTopHeader span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.filterItem {
  height: 40px;
}



.marginTop20 {
  margin-top: 20px !important;
}

.padingTop25Px {
  padding-top: 25px !important;
}

.stickyFareSummryOnLargeDevice {
  position: sticky;
  top: 60px;
}

.fareSummaryTitle {
  display: flex;
  justify-content: space-between;

}

.fareSummaryTitle span {
  cursor: pointer;
  user-select: none;

}

.marginTop1Px {
  margin-top: 1px !important;
}


.paymentType {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding-top: 15px;
  flex-wrap: wrap;
  /* Allows items to wrap on small devices */
}

.paymentType>div {
  flex: 1 1 100%;
  /* Full width by default */
}

.padding5Px {
  padding: 5px !important;
}

.customCardHeight110px {
  height: 110px !important;
}

.customETicketWrapper {
  margin-top: 60px !important;
}

.payment-failed-wrapper {
  margin-top: 65px;
  display: flex;
  justify-content: center;
}

.message-box_failed {
  text-align: center;
  border: 1px solid #e0d7d7;
  border-radius: 10px;
  padding: 15px;
}
@keyframes blink {
  50% { opacity: 0; }
}

.animate-blink {
  animation: blink 1s infinite;
}
.partner-credit{
  text-decoration: none;
    color: #fff;
    font-size: 14px;
    position: relative;
    display: inline-block;
    padding-top: 14px;
}
.creditTitleMediumDevice{
  color: white;
  font-size: 13px;
  display: none;
}
@media (min-width: 768px) {
  .paymentType>div {
    flex: 1 1 calc(50% - 15px);
    /* Two divs side by side */
  }
}

@media (max-width: 767px) {
  .padTop60 {
    padding-top: 5px !important;
  }
}

.redirectMessage {
  font-size: 13px;
  margin-top: 5px;
}

.payment-check-icon {
  font-size: 40px;
  /* Adjust size */
  color: green;
  /* Adjust color */
  animation: popScale 0.6s ease-in-out infinite alternate;
}

.animated-icon {
  font-size: 40px;
  /* Adjust size */
  color: red;
  /* Adjust color */
  animation: popScale 0.6s ease-in-out infinite alternate;
}

@keyframes popScale {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}

/* End Here */
/*  */
/* Media Query */

@media only screen and (min-width: 768px) and (max-width: 935px) {
  .cardContainer .cardTitle {
    font-size: 14px;
  }

}

@media only screen and (max-width: 1207px) {
  .cusSesctionPaddingTop {
    padding-top: 57px !important;
  }

}

@media only screen and (max-width: 768px) {
  .cusSesctionPaddingTop {
    padding-top: 0px !important;
  }

}

@media only screen and (max-width: 1400px) {
  .departureTimeContainer .item .dtitle {
    text-wrap: nowrap;
    font-size: 12px;
  }

  .departureTimeContainer .item .dsubtitle {
    text-wrap: nowrap;
    font-size: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .section_padding {
    padding: 0;
  }

  .no-flights-message {
    margin-bottom: 80px;
  }

  .flight-details-block {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 992px) {
  .fiight-search-filter {
    display: none;
  }

  .flight-filter-bottom {
    display: block !important;
  }

  .rf-left-sidebar {
    margin-top: 15px !important;
  }

  .flight-booking-container {
    margin-top: 30px !important;
  }

  .fb-left-sidebar {
    margin-top: 0px !important;
  }

  .payment-method-container {
    margin-top: 0px !important;
  }
  .creditTitleMediumDevice{
    display: block;
  }
  .creditTitle{
    display: none;
  }
}

/* For Review Flight */
@media only screen and (max-width:1199px) {
  .baggage-container {
    padding-top: 0px;
  }

  .baggage-container-main {
    padding-top: 2px !important;
  }

  .departureTimeContainer .item .dtitle {
    font-size: 10px;
  }

  .departureTimeContainer .item .dsubtitle {
    font-size: 10px;
  }

  .departureTimeContainer {
    gap: 15px;
  }

  .timeSlotNight {
    margin-left: 5px;
  }
}

@media only screen and (max-width:550px) {
  .rf-flight-d {
    border: none;
  }

  .rf-arrival-details {
    justify-content: end !important;
  }

  .review-flight-details {
    margin-top: 0px;
  }

  .review-flight-container {
    margin-top: 15px;
  }

  .flight_multis_area_wrapper {
    padding: 0px;
  }

  .flight_show_down_wrapper {
    padding-top: 0px;
  }
}

@media only screen and (max-width:510px) {
  .review-flight-container {
    box-shadow: none;
    padding: 0px;
    margin-top: 10px;
  }

  .rf-flight-details {
    margin-top: 25px !important;
  }
}

@media only screen and (max-width:497px) {
  .rf-conatiner {
    width: 100% !important;
    padding: 0px !important;
  }

  .flight_inner_show_component .flight_det_wrapper {
    width: 150px;
  }
}