.star-rating {
    display: flex;
    direction: row;
  }
  
  .star-rating .star {
    font-size: 2rem;
    cursor: pointer;
  }
  
  .star-rating .on .star {
    color: gold;
  }
  
  .star-rating .off .star {
    color: grey;
  }
  