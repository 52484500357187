.c-modal-header{
    border: none;
}
.icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -50px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82ce34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
.icon-box i {
    font-size: 50px;
    position: relative;
    top:9px;
}
.heading-text{
    text-align: center;
    margin: 30px 0 -15px !important;
}
.modal-confirm-btn{
    color: #fff;
    border-radius: 4px;
    background: #8b3eea;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}
.txt-black{
    color: black;
}
.m-content{
    margin-top: 50px;
}