.cus-h4{
    font-size: 16px;
    color: #727178;
    font-weight: 500;
    padding-bottom: 8px;
}
.cus-subheading{
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    outline: 0 !important;
    color: #8b3eea;
}
.cus-button{
    color: var(--white-color);
    background-color: #8b3eea;
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
    border: none;
}
.cus-button:hover{
    background-color:black;
    color: white;
    transition: all 0.3s ease-in-out 0.1s;
}