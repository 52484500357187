/* Base styles for the modal */
.custom-modal .modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    z-index: 1000;
}

.custom-modal .modal-content {
    background-color: white;
    width: 100%;
    max-width: 500px;
    border-radius: 25px 25px 0 0;
    padding: 20px 0px 0px 10px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    transition: transform 0.3s ease;
    margin-bottom: 77px;
}

.custom-modal .modal-body {
    max-height: 370px;
    min-height: 300px;
    overflow: auto;
}

.custom-modal .modal.show {
    visibility: visible;
    opacity: 1;
}

.custom-modal .modal.open .modal-content {
    transform: translateY(0);
}

.custom-modal .modal.close .modal-content {
    transform: translateY(100%);
    transition: transform 0.3s ease;
}

.custom-modal .close {
    font-size: 2.5em;
    cursor: pointer;
    color: black;
    float: right;
    line-height: 1;
    border: none;
    background: none;
}

.custom-modal .close:focus {
    outline: none;
    color: #333;
}

.custom-modal .cusmodal-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 11px;
}

.custom-modal .cusmodal-title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
    padding-right: 35px;
}
.btn-clear {
    font-size: 20px;
    font-weight: 500;
    color: #4d0c71;
}

.btn-showFlight {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.flight-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  .flight-logo {
    width: 40px;
    height: 40px;
  }
  .flight-info {
    flex: 1;
    margin-left: 10px;
  }
  .flight-name {
    font-weight: bold;
    color: black;
    margin: 0;
  }
  .flight-number {
    margin: 0;
  }
  .flight-checkbox{
    height: 22px;
    width: 22px;
    border: 1px solid #848794;
  }

  .radio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
  }

  .radio-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .form-check-input {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #007bff;
    border-radius: 50%;
    outline: none;
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  .form-check-input:checked {
    background-color: #007bff;
    border-color: #007bff;
  }

  .form-check-label {
    font-size: 14px;
    font-weight: 500;
  }

  .radio-description {
    font-size: 12px;
    font-weight: 500;
    color: #495057;
  }

  .text-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .radio-label-right{
    margin-right: 50px;
  }

  .fiter-applied{
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
  }