.customBody {
    font-family: sans-serif;
    margin: 0;
    padding: 10px;
    color: #000;
    font-size: 14px;
}

.wrapper-cnt {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    /* Ensure padding and border are accounted for */
}

.wrapper-cnt * {
    box-sizing: inherit;
    /* Ensure all child elements also respect box-sizing */
}

.info-section {
    margin-bottom: 15px;
    width: 100%;
    overflow: auto;
}

.payment-section table {
    width: 100%;
    border-collapse: collapse;
}

.payment-section th,
.payment-section td {
    padding: 7px;

}

.payment-section tbody {
    border: 2px solid #DBE2E8;
}

.payment-section th {
    background-color: #DBE2E8;
    text-align: left;
}

.advertisement {
    text-align: center;
    margin-top: 20px;
    border-top: 2px solid #ddd;
    padding-top: 20px;
    width: 100%;
    max-height: 300px;

}

.advertisement img {
    width: 100%;
    height: 300px;
    object-fit: scale-down;
    border: 2px dashed #ddd;
}



.header {
    text-align: center;
    margin-bottom: 10px;
    float: left;
    width: 100%;
}

.header h1 {
    font-size: 24px;
    margin: 0 0 5px;
}

.topleft {
    line-height: 22px;
}



table {
    width: 100%
}

.info-section table {
    border-top: 2px solid #DBE2E8;
    border-bottom: 4px solid #DBE2E8;
    border-left: 2px solid #DBE2E8;
    border-right: 2px solid #DBE2E8;
    padding: 5px 0;
    border-collapse: collapse;
}

.info-section table tbody tr {
    border-bottom: 4px solid #DBE2E8;
}

.info-section table tr th {
    text-align: left;
    border-bottom: 1px solid #DBE2E8;
    padding: 5px;
}

.info-section table tr td strong {
    color: #858a8e;
    font-weight: 600;
}

.info-section table tr td {
    text-align: left;
    padding: 5px;
}

strong.blacktext {
    color: #000 !important;
}

td.lineh {
    line-height: 23px;
}

.remarks p {
    text-align: justify;
    line-height: 20px;
}

.remark-custome p {
    text-align: justify;
    line-height: 20px;
}

.remarks {
    margin-top: 19px;
    float: left;
    width: 60%;
    padding: 0 0px 0 0;
}

.payy {
    float: right;
    width: 40%;
}

.payment-section {
    padding: 20px;
}

.paydetails {
    border: 2px solid #DBE2E8;
    padding: 10px;
}

.paydetails-total {
    border: 2px solid #DBE2E8;
    padding: 10px;
}

.paydetails-total span {
    float: right;
}

.paydetails-total {
    font-weight: 600;
    color: #666666;
}

.paydetails tr td {
    border: 2px solid #DBE2E8;
}

.watermark {
    /* background: url('../../../../assets/ETicket/watermark.png'); */
    background-repeat: no-repeat;
    background-size: contain;
}

.arrowImage {
    height: 17px;
}

/* .eTicketFooter{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    gap: 20px;
   
}
.eTicketFooter .content{
    background-color: #f0f0f0;
    padding: 7px 20px;
    border-radius: 5px;
    cursor: pointer;

}
.eTicketFooter .content button{
    border: none;
    color: #1c1cd6ab;
    font-weight: 600;
} */
/* Ensure the footer is fixed at the bottom */
.eTicketFooter {
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    position: relative;
    flex-wrap: wrap;
}

/* Ensure buttons wrap and adjust to fit screen size */
.eTicketFooter .content {
    padding: 7px 15px;
    background-color: #f0f0f0;
    padding: 7px 20px;
    border-radius: 5px;
    flex-shrink: 0;
    width: auto;
}

.eTicketFooter .content button {
    border: none;
    color: #1c1cd6ab;
    font-weight: 600;
}

.editpaymentcontainer {
    padding-top: 5px;
    text-align: end;
}

.btn-update {
    background-color: #28a745;
    color: #ffffff;
    border: none;
    padding: 5px 13px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.btn-update:hover {
    background-color: #218838;
}

.btn-cancel {
    background-color: #ef395d;
    color: #ffffff;
    border: none;
    padding: 5px 13px;
    margin-right: 5px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.btn-cancel:hover {
    background-color: #a72841;
}

.customInput {
    width: 100px;
}


.remark-pydetails-wrapper {
    display: grid;
    grid-template-columns: 60% auto;
}

.remark-custome p {
    font-size: 12px;
}

/* Responsive behavior for buttons */
@media (max-width: 767px) {
    .eTicketFooter .content {
        width: 48%;
        text-align: center;
    }

    .customBody {
        padding: 0px;
    }
}

@media (max-width: 847px) {
    .remarks p {
        font-size: 12px;
    }

    .remark-custome p {
        font-size: 12px;
    }

    .payment-section {
        font-size: 12px;
    }
}

@media (max-width: 769px) {
    .wrapper-cnt {
        padding: 8px;
    }

    .remarks p {
        font-size: 10px;
    }

    .remark-custome p {
        font-size: 10px;
    }

    .payment-section {
        padding-top: 4px;
        padding-left: 7px;
        font-size: 10px;
    }
}

@media (max-width: 567px) {

    .topleft {
        font-size: 10px;
    }

    .info-section table tbody {
        font-size: 13px;
    }

    .info-section .arrowImage {
        height: 6px;
    }

    .subheading {
        text-wrap: nowrap;
    }

}

@media (max-width: 375px) {

    .remark-pydetails-wrapper {
        grid-template-columns: 52% auto;
    }

}

/* @media print {
    .remark-pydetails-wrapper {
        grid-template-columns: auto;
    }
} */

.remark-pydetails-wrapper-print {
    grid-template-columns: auto !important;
}