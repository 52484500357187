.faq-container {
    padding: 10px;
}

.custom-box-shadow {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.faq-title {
    margin: 10px 0px 20px 0px;
}

.faq-title-details {
    margin-top: 10px;
    color: black;
}

.custom-padding {
    padding: 0px 25px;
}