.cus-padding-bottom-left-10{
    padding: 0px 0px 10px 10px;
}
.bg-color-main{
    background-color: #8b3eea;
}

/*  */
.search-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 20px;
    border: 2px solid #ddd;
    border-radius: 5px;
    width: auto;
}

.search-input,
.date-input,
.custom-select,
.search-button {
    padding: 10px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.search-input {
    flex-grow: 1;
}

.date-input-container {
    display: flex;
}

.date-input {
    max-width: 150px;
}

.custom-select {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
}

.search-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: #0056b3;
}

/* dd */

/* .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  } */
  
  /* Dropdown content (positioned above) */
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
    height: 50vh;
    /* margin-top: -180px;  */
    overflow: auto;
    cursor: pointer;
}
.dropdown-contentNew {
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
    height: 50vh;
    /* margin-top: -180px;  */
    overflow: auto;
    cursor: pointer;
    padding: 10px;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px 16px;
    display: block;
}


/* Change color of dropdown links on hover */
.dropdown-content span:hover {
    background-color: #f1f1f1;
}
.dropdown-contentNew div:hover {
    background-color: #f1f1f1;
}
.btn-loader{
    height: 23px;
}
/* CustomDatepicker.css */

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    height: 55px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.react-datepicker__input-container input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-datepicker {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ced4da;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: #fff;
}

.react-datepicker__day:hover {
    background-color: #e9ecef;
    color: #495057;
}
.post-review-success{
    color: green;
    font-weight: 700;
}
.post-review-error{
    color: red;
    font-weight: 700;
}
.review-container{
    height: 25vh;
    overflow: auto;
}
.color-common{
    color: #8b3eea;
}
.cus-w-20{
    width: 25%;
}
.cus-w-80{
    width: 75%;
}
.sector-code-btn-p{
    padding-top: 5px;
    
}
.sector-code-btn :hover{
   background-color: #f9f9f9;
   color: black;

}
.sector-code-btn{
    color: black !important;
    border: 1px solid #6c757d47 !important;
    margin-right: 10px !important;
}
.sector-Name{
    font-weight: 500;
}
.sector-code-c{
    margin-top: -4px;
    text-wrap: balance;
}
@media(max-width: 769px){
    .cus-filter{
        padding-right: 40px !important;
    }
}
@media(max-width: 768px){
    .cus-mt{
        margin-top: 10px;
        text-align: center;
    }
}

