.cus-btn-theme{
    color: var(--white-color) !important;
    background-color: var(--main-color) !important;
    transition: var(--transition) !important;
    box-shadow: none !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    position: relative !important;
    z-index: 0 !important;
    border: none !important;
}.tour-options {
    margin: 20px 0;
  }
  
  .tour-options p {
    margin: 0;
    font-weight: bold;
  }
  
  .tour-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tour-list li {
    display: inline-block;
  }
  
  .tour-list input[type="radio"] {
    display: none;
  }
  
  .tour-list label {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f5f5f5;
    color: #000;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap; /* Ensure text doesn't wrap */
  }
  
  .tour-list label.selected {
    background-color: red;
    color: white;
  }
  
  .tour-list label:hover {
    background-color: red;
    color: white;
  }
  .cus-price-box{
    margin-top: 30px;
  }
  .cus-placeholder-color{
    color: #5b6268;
  }
  .cus-calendar-center{
    display: flex;
    justify-content: center;
  }
  .cus-cursor-pointer{
    cursor: pointer;
  }
  .cus-mt-20{
    margin-top: 20px;
  }
  .td-discounted-price{
    font-size: 25px;
    font-weight: 500;
  }
  .td-base-price{
    text-decoration: line-through;
    color: #888;
    margin-right: 10px;
    font-size: 18px;
  }
  .sub-color{
    color: #5c5656;
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .tour-list {
      flex-direction: column;
    }
  
    .tour-list label {
      width: 100%; /* Make each label take full width on smaller screens */
      text-align: center;
    }
  }
  
  