.invoice-box {
    max-width: 800px;
    margin: auto;
    background: #ffffff;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    color: #555;
}

.cheader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.company-details h1 {
    color: #333;
    font-size: 32px;
    margin-bottom: 10px;
}

.company-details p {
    line-height: 1.5;
    font-size: 14px;
    color: #777;
}

.invoice-info {
    text-align: right;
}

.invoice-info h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.invoice-info p {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.invoice-info span {
    color: #333;
    font-weight: bold;
}

.client-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.client-details div {
    flex: 1 1 45%;
    margin-bottom: 10px;
}

.client-details h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    border-bottom: 2px solid #f4f4f4;
    padding-bottom: 5px;
}

.client-details p {
    font-size: 14px;
    color: #777;
    line-height: 1.5;
}

.invoice-items {
    margin-bottom: 30px;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

.invoice-items table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; /* Adjust as necessary */
}

.invoice-items th, .invoice-items td {
    padding: 12px;
    border: 1px solid #eee;
    text-align: left;
    font-size: 14px;
}

.invoice-items th {
    background-color: #f4f4f4;
    color: #333;
}

.invoice-items tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.invoice-items tbody tr.total-row td {
    font-weight: bold;
    background-color: #f4f4f4;
}

.payment-info h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    border-bottom: 2px solid #f4f4f4;
    padding-bottom: 5px;
}

.payment-info p {
    font-size: 14px;
    color: #777;
    line-height: 1.5;
}

footer {
    text-align: center;
    margin-top: 40px;
    font-size: 12px;
    color: #aaa;
    border-top: 1px solid #eee;
    padding-top: 10px;
}

/* Download Button Styling */
.download-button {
    text-align: right;
    margin-bottom: 20px;
}

.download-button button {
    background-color: #2980b9;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.download-button button:hover {
    background-color: #1f6391;
}
.c-overflow{
    overflow: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
    .cheader, .client-details {
        flex-direction: column;
        align-items: flex-start;
    }

    .invoice-info {
        text-align: left;
        margin-top: 20px;
    }

    .client-details div {
        flex: 1 1 100%;
    }

    .invoice-items th, .invoice-items td {
        font-size: 12px; /* Adjust font size for readability */
    }

    .invoice-items th:nth-child(1),
    .invoice-items td:nth-child(1) {
        width: 30%; /* Adjust width for the Date column */
    }

    .invoice-items th:nth-child(2),
    .invoice-items td:nth-child(2) {
        width: 10%; /* Adjust width for the DepTime column */
    }

    .invoice-items th:nth-child(3),
    .invoice-items td:nth-child(3) {
        width: 20%; /* Adjust width for the Hand/Check Bag column */
    }

    .invoice-items th:nth-child(4),
    .invoice-items td:nth-child(4) {
        width: 20%; /* Adjust width for the From column */
    }

    .invoice-items th:nth-child(5),
    .invoice-items td:nth-child(5) {
        width: 20%; /* Adjust width for the To column */
    }

    .download-button {
        text-align: left;
    }

    .download-button button {
        width: 100%;
    }
}

/* Print Styles */
@media print {
    body {
        background-color: #fff;
    }

    .invoice-box {
        box-shadow: none;
        border: none;
        margin: 0;
        padding: 0;
    }

    .cheader, .client-details, .invoice-items, .payment-info, footer {
        page-break-inside: avoid;
    }

    .download-button {
        display: none;
    }
}
