/* Ensuring that the parent container uses flexbox for alignment */
.Trending_tour_slider .owl-stage {
    display: flex;
    align-items: stretch; /* Ensures items stretch to the same height */
}

/* Styling each card */
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 90%; /* Full height of the container */
    margin: 10px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
}

.card-img {
    flex: 1 1 auto; /* Allows the image to grow and shrink */
    text-align: center;
}

.card-img img {
    max-width: 100%;
    height: auto; /* Keeps the image ratio */
}

.card-content {
    flex: 0 0 auto; /* Prevents the content from growing and shrinking */
}

.theme_two_box_content h4, .theme_two_box_content p, .theme_two_box_content h3 {
    margin: 10px 0;
}

.card-content h4 a {
    color: #333;
    text-decoration: none;
}

.card-content h3 {
    color:black;
}
/* Price section styling */
.price-section {
    display: flex;
    align-items: center;
}

.base-price {
    text-decoration: line-through;
    color: #888;
    margin-right: 10px;
    font-size: 14px;
}

.discounted-price {
    font-size: 16px;
    font-weight:500;
}

.discount-percentage {
    
    color: #28a745;
    font-weight: bold;
}

/* Buttons */



/* Responsive design */
@media (max-width: 768px) {
    .card {
        margin: 5px;
        padding: 10px;
    }
}
