/* Calendar.css */

.calendar-container {
    padding: 20px;
}

.calendar-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    gap: 25px;
}

.prev-button,
.next-button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.month-title {
    font-size: 24px;
    font-weight: bold;
}

.calendar-grid {
    display: flex;
    justify-content: flex-start;
}

.month-card {

    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.month-header {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    text-align: center;
}

.day-name {
    padding: 8px;
}

.days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    margin-top: 10px;
}

.day-box {
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: relative;
}

.day-number {
    font-weight: bold;
}

.note-box {
    margin-top: 5px;
    font-size: 12px;
    padding: 5px;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.past-day {
    color: #aaa;
}

.today {
    background-color: #b3e0ff;
}

.today .day-number {
    color: #007bff;
}

.prev-button:hover,
.next-button:hover {
    background-color: #e0e0e0;
}